import React from 'react';
import Layout from 'components/layout';
import Seo from 'components/seo';
import { Col, Row, Typography } from 'antd';
import { RichTextViewer } from 'components/rich-text-viewer';
import { AboutPageData, StrapiImage } from 'models';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql, PageProps } from 'gatsby';

interface AboutBlockProps {
  data: { picture: StrapiImage; title: string; content: string };
  reverse?: boolean;
}
const AboutBlock: React.FC<AboutBlockProps> = ({ data, reverse }) => (
  <Row
    gutter={32}
    style={{
      flexDirection: reverse ? `row-reverse` : `row`,
      marginTop: `100px`,
      marginBottom: `100px`,
    }}
    align="stretch"
  >
    {data?.picture && (
      <Col
        xs={24}
        sm={10}
        md={10}
        style={{ minHeight: `350px`, marginBottom: `15px` }}
      >
        <GatsbyImage
          alt={data.picture.alternativeText}
          image={getImage(data.picture.localFile)}
        />
      </Col>
    )}
    <Col xs={24} sm={!data?.picture ? 24 : 14}>
      <Typography.Title level={3}>{data?.title}</Typography.Title>
      <RichTextViewer rawContent={data?.content} />
    </Col>
  </Row>
);

interface AboutPageQueryRes {
  aboutPage: AboutPageData;
}

const About: React.FC<PageProps<AboutPageQueryRes>> = ({ data }) => {
  const { aboutPage } = data;

  const getAge = (dateString: string) => {
    const today = new Date();
    const birthDate = new Date(dateString);
    const age =
      (today.getFullYear() - birthDate.getFullYear()) * 12 +
      (today.getMonth() - birthDate.getMonth());
    if (age >= 12) {
      return `${Math.round(age / 12)} ans`;
    }
    return `${age} mois`;
  };

  return (
    <Layout>
      <Seo seo={aboutPage.seo} />

      <Row justify="center">
        <Col xs={{ span: 24 }} sm={{ span: 20 }} md={{ span: 16 }}>
          <Typography.Title level={1}>
            A propos de motorbikepacker
          </Typography.Title>
          <RichTextViewer rawContent={aboutPage?.intro} />
        </Col>
      </Row>
      <Row
        gutter={32}
        style={{ marginTop: `100px`, marginBottom: `100px` }}
        justify="center"
        align="top"
      >
        <Col
          style={{ textAlign: `center` }}
          xs={{ span: 24 }}
          sm={{ span: 20 }}
          md={{ span: 24 }}
        >
          <Typography.Title level={2}>Qui sommes-nous?</Typography.Title>
        </Col>
        {aboutPage?.teamMember.map((current, itr: any) => (
          <Col
            xs={{ span: 20 }}
            sm={{ span: 12 }}
            md={{ span: 8 }}
            // span={12}
            // xs={24}
            // sm={12}
            key={`tm-${current.id}-${itr}`}
            style={{ textAlign: `center` }}
          >
            <div
              style={{
                margin: `auto`,
                width: `105px`,
                height: `105px`,
                position: `relative`,
                objectFit: `fill`,
                borderRadius: `999px`,
                overflow: `hidden`,
                marginBottom: `25px`,
              }}
            >
              <GatsbyImage
                alt={current.picture.alternativeText}
                image={getImage(current.picture.localFile)}
              />
            </div>
            <Typography.Title level={3}>
              {current.name}, {getAge(current.birthDate)}
            </Typography.Title>
            <Typography.Paragraph
              style={{ marginTop: `0`, fontStyle: `italic` }}
              type="secondary"
            >
              {current.shortDescription}
            </Typography.Paragraph>
            <Typography.Paragraph>{current.description}</Typography.Paragraph>
          </Col>
        ))}
      </Row>
      <Typography.Title level={2}>
        Bon, concrètement vous faites quoi?
      </Typography.Title>
      <AboutBlock data={aboutPage?.what} />
      <Typography.Title level={2}>
        Terrain de jeu et organisation
      </Typography.Title>
      <AboutBlock data={aboutPage?.whereProject} reverse />
      <AboutBlock data={aboutPage?.when} />
      <AboutBlock data={aboutPage?.howMuch} />
    </Layout>
  );
};

export default About;

export const query = graphql`
  query AboutPageQuery {
    aboutPage: strapiAboutPage {
      howMuch {
        content
        id
        title
      }
      intro
      seo {
        metaDescription
        id
        metaTitle
        ogType
      }
      updated_at(locale: "fr")
      teamMember {
        birthDate(locale: "fr")
        description
        name
        id
        picture {
          alternativeText
          caption
          name
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 105
                height: 105
                placeholder: DOMINANT_COLOR
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        shortDescription
      }
      what {
        content
        id
        title
        picture {
          alternativeText
          caption
          name
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 475
                placeholder: DOMINANT_COLOR
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
      when {
        content
        id
        title
      }
      whereProject {
        content
        id
        title
        picture {
          alternativeText
          caption
          name
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 475
                placeholder: DOMINANT_COLOR
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  }
`;
